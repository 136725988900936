<template>
  <div>
    <div
      class="card login_card d-flex border-0 rounded-0"
    >
      <div class="card-body p-0">
        <div class="d-flex justify-content-center">
          <img
            src="@/assets/images/logo.svg"
            alt=""
            class="img-fluid"
          >
        </div>
        <p class="text-center">
          吉品後台管理系統
        </p>
        <form @submit.prevent="login()">
          <div class="form-group">
            <label
              for="login_account"
              class="sr-only"
            >登入 ID</label>
            <input
              id="login_account"
              v-model="admin.account"
              type="text"
              class="form-control"
              placeholder="登入 ID"
              required
              autofocus
            >
          </div>
          <div class="form-group">
            <label
              for="login_password"
              class="sr-only"
            >密碼</label>
            <input
              id="login_password"
              v-model="admin.password"
              type="password"
              class="form-control"
              placeholder="密碼"
              required
            >
          </div>
          <button
            class="btn btn-block rounded-0"
            type="submit"
          >
            登入
          </button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { API_ADMIN_LOGIN } from '@/plugins/api';

export default {
  data() {
    return {
      admin: {
        loginType: 'admin',
        account: '',
        password: '',
      },
      login_times: 0,
      login_permit: true,
    };
  },
  watch: {
    login_permit: {
      async handler(newValue, oldValue) {
        const vm = this;
        if (!newValue) {
          setTimeout(() => {
            vm.login_permit = true;
            vm.login_times = 0;
          }, 600000);
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    /* 登入 */
    async login() {
      const vm = this;
      if (vm.login_times > 6) {
        vm.login_permit = false;
        vm.$swal({
          icon: 'error',
          title: '錯誤!',
          text: '請10分鐘後再登入',
          confirmButtonText: '關閉視窗',
        });
        return;
      }
      vm.login_times += 1;
      const response = await API_ADMIN_LOGIN(vm.admin);
      const res = response.data;
      if (res.code === '0000') {
        vm.$store.dispatch('admin/save_loginstatus', {
          adminName: res.data.adminName,
          adminId: vm.admin.account,
          loginStatus: true,
          loginToken: res.data.token,
        });
        vm.login_times = 0;
        vm.$router.push('/dashboard');
      }
    },
  },
};
</script>
